<template>
  <div class="dt-block">
  
    <div class="dt-header">
      <div class="dt-header-main">
        <el-button
          icon="el-icon-back"
          circle
          size="small"
          @click="$router.go(-1)"
        >
        </el-button>
        <span style="margin-left: 20px;">Добавить карту клиента</span>
      </div>
      <div>
        <el-button
          icon="el-icon-refresh"
          circle
          @click="refresh"
        >
        </el-button>
      </div>
    </div>
  
    <div class="dt-data">

      <div class="dt-form">
        
        <el-form
          ref="form"
          :model="form"
          label-width="180px"
          v-loading="loading"
        >
        
          <el-divider content-position="left">Карта клиента</el-divider>
          
          <el-form-item label="Название">
            <el-input v-model="form.name" style="max-width: 400px;"></el-input>
          </el-form-item>
          
          <el-form-item
            label="Менеджер"
          >
            <el-select
              v-model="form.manager_id"
              filterable
            >
              <el-option
                v-for="m in options.managers"
                :key="m.value"
                :label="m.label"
                :value="m.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item
            label="Второй менеджер"
          >
            <el-select
              v-model="form.manager_p_id"
              filterable
            >
              <el-option
                v-for="m in options.managers"
                :key="m.value"
                :label="m.label"
                :value="m.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="ID (Трейд)">
            <el-input v-model="form.trade_su_user_id" style="width: 140px;"></el-input>
          </el-form-item>

          <el-divider content-position="left">Администратор карты клиента</el-divider>
            
          <el-form-item label="Имя">
            <el-input v-model="form.admin_name" style="max-width: 400px;"></el-input>
          </el-form-item>
          
          <el-form-item label="E-mail">
            <el-input v-model="form.admin_email" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-form-item label="Пароль">
            <el-input v-model="form.admin_password" style="max-width: 400px;">
              <i
                class="el-icon-refresh el-input__icon"
                slot="suffix"
                @click="generatePassword">
              </i>
            </el-input>
          </el-form-item>

          <el-form-item label="Телефон">
            <el-input v-model="form.admin_phone" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-form-item label="Должность">
            <el-input v-model="form.admin_position" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-divider content-position="left">Компания (юридическое лицо)</el-divider>

          <el-form-item label="Название">
            <el-input v-model="form.company_name" style="max-width: 400px;">
              <i
                class="el-icon-document-copy el-input__icon"
                slot="suffix"
                @click="copyName">
              </i>
            </el-input>
          </el-form-item>
          
          <el-form-item label="ИНН">
            <el-input v-model="form.company_inn" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-divider content-position="left">Комментарий менеджера</el-divider>

          <el-form-item label="Комментарий">
            <el-input
              style="max-width: 400px;"
              type="textarea"
              :rows="3"
              placeholder="Комментарий"
              v-model="form.comment"
            >
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="addCard">Добавить</el-button>
          </el-form-item>

        </el-form>

      </div>

    </div>
  
  </div>
</template>

<script>
import { Notification } from 'element-ui';

export default {

  name: 'CardsAdd',

  props: {
    card_id: String,
  },

  data() {
    return {
      form: {
        name: '',
        manager_id: 0,
        manager_p_id: 0,
        trade_su_user_id: '',
        admin_name: '',
        admin_email: '',
        admin_password: '',
        admin_phone: '',
        admin_position: '',
        company_name: '',
        company_inn: '',
        comment: '',
      },
      options: {
        managers: [ { label: 'Не назначен', value: 0 } ],
      },
      loading: false,
    }
  },
  
  mounted() {
    console.log('R: Request', this.$router.currentRoute.path, this.$router.currentRoute.query);
    this.get_managers();
    
    const q = this.$router.currentRoute.query;
    if(q) {
      if(q.user_id) {
        this.form.trade_su_user_id = q.user_id;
      }
      if(q.inn) {
        this.form.company_inn = q.inn;
      }
      if(q.firm) {
        this.form.name = q.firm;
        this.form.company_name = q.firm;
      }
      if(q.fio) {
        this.form.admin_name = q.fio;
      }
      if(q.phone) {
        this.form.admin_phone = q.phone;
      }
      if(q.email) {
        this.form.admin_email = q.email.split(/[ ,\s]+/)[0];
      }
    }
    
  },

  methods: {

    /* refresh */
    refresh() {
      this.get_managers();
    },

    /* get_managers */
    get_managers() {
      this.loading = true;
      this.$request({
        path: '/new/user/manager/options',
        data: {},
        call: [ this.get_managers_complete, this ],
        cache: false,
      });
    },
    
    /* get_managers_complete */
    get_managers_complete(status, data) {
      if(status == 200) {
        this.options.managers.splice(1, this.options.managers.length - 1);
        Array.prototype.push.apply(this.options.managers, data.managers);
      }
      this.loading = false;
    },
    
    /* addCard */
    addCard() {
      this.loading = true;
      this.$request({
        path: '/new/card/add',
        data: this.form,
        call: [ this.addCard_complete, this ],
        cache: false,
      });
    },
    
    /* addCard_complete */
    addCard_complete(status, data) {
      this.loading = false;
      if(status == 200) {
        this.$router.push({ path: '/digitender/clients/cards/view/' + data.card.id.toString() });
      }
      else {
        if(data._alert) {
          Notification({
            title: data._alert,
            message: '',
            customClass: 'd-update-error',
            //duration: 0,
          });
        }
      }
    },
    
    /* generatePassword */
    generatePassword() {
      var password = '';
      const characters = '0123456789';
      const charactersLength = characters.length;
      for(var i = 0; i < 9; i++)
        password += characters.charAt(Math.floor(Math.random() * charactersLength));
      this.form.admin_password = password;
    },
    
    /* copyName */
    copyName() {
      this.form.company_name = this.form.name;
    },
    
  },
  
};

function parseDatetime(s, fromUTC) {
  var d = null;
  const sp = s.split(/[- :]/);
  if(fromUTC) {
    d = new Date(Date.UTC(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0));
  }
  else {
    d = new Date(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0);
  }
  return d;
}

function formatDatetime(v, f, fromUTC, toUTC) {
  const fm = typeof f !== 'string' ? 'YYYY-MM-DD hh:mm:ss' : f;
  var d = typeof v === 'string' ? d = parseDatetime(v, fromUTC) : v;
  const mask = toUTC ?
    {
      'YYYY': d.getUTCFullYear(),
      'MM': ('0' + (d.getUTCMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getUTCDate()).slice(-2),
      'hh': ('0' + d.getUTCHours()).slice(-2),
      'mm': ('0' + d.getUTCMinutes()).slice(-2),
      'ss': ('0' + d.getUTCSeconds()).slice(-2),
    } :
    {
      'YYYY': d.getFullYear(),
      'MM': ('0' + (d.getMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getDate()).slice(-2),
      'hh': ('0' + d.getHours()).slice(-2),
      'mm': ('0' + d.getMinutes()).slice(-2),
      'ss': ('0' + d.getSeconds()).slice(-2),
    };
  const str = fm.replace(/(Y+|M+|D+|h+|m+|s+)/g, (s, g) => mask[g]);
  return str;
}
</script>

<style scope>
.el-notification.d-update-error {
  background-color: #F56C6C;
  border: 0;
}

.d-update-error .el-notification__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.d-update-error .el-notification__title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.d-update-error .el-notification__closeBtn {
  position: static;
  margin-top: 2px;
  color: rgba(255, 255, 255, .6);
}

.dt-header {
  background-color: rgb(246, 248, 248);
  border-bottom: 1px solid rgb(222, 229, 231);
  padding: 20px;
  height: 80px;
  line-height: 40px;
  font-size: 22px;
  font-weight: 300;
  color: rgb(88, 102, 110);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dt-data {
  padding: 0px;
}

.dt-form {
  padding: 20px 20px 0px 20px;
  margin-bottom: 2px;
  flex: 1 1 100%;
}

.dt-info {
  padding: 20px 20px 0px 20px;
  margin-bottom: 40px;
  flex: 0 0 320px;
}

.dt-sub {
  font-size: 16px;
  color: rgba(0, 0, 0, .5);
  margin-left: 16px;
}

.dt-subheader {
  background-color: rgb(246, 248, 248);
  border-bottom: 1px solid rgb(222, 229, 231);
  border-top: 1px solid rgb(222, 229, 231);
  padding: 10px 20px 10px 20px;
  height: 60px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 300;
  color: rgb(88, 102, 110);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dt-subheader.hitting {
  position: relative;
  margin-top: -1px;
  z-index: 1;
}

.el-divider__text {
  background-color: rgb(240, 243, 244) !important;
}

.el-textarea__inner {
  min-height: 50px !important;
}
</style>
